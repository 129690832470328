import React, { forwardRef, useEffect, useState } from "react"
import { StaticImage } from "src/UILibrary"
import Icons from "src/assets/icons"
import Container from "src/components/container"
import { ConversationHandle, USER_TYPE } from "src/constants/enums"
import { MAX_MESSAGE_LENGTH_BEFORE_HIDING } from "src/constants/common"
import { ConversationMessageTypes } from "src/constants/enums"
import { getLastMessagePrefix } from "../utils"
import { axiosInstance } from "src/modules/axios"
import { UserAvatar } from "./UserAvatar"
import { useChat } from "../context/chatContext"
import { useRecoilValue } from "recoil"
import { currentLangState } from "src/states/signup"
import LoadingSpinner from "src/assets/icons/spinner.svg"
import useIsRtl from "src/hooks/useIsRtl"

export const ChatMessage = forwardRef<any, any>(
  (
    {
      message,
      date_time,
      audio_url,
      message_type_id,
      size,
      user_id,
      user_name,
      user_name_initials,
      group_name,
      examRoomId,
      physicianRoomId,
      file_name,
      conversation_handler,
    },
    loadingMessageRef
  ) => {
    const [finalMessage, setFinalMessage] = useState("")
    const [isReadMore, setIsReadMore] = useState(false)
    const { userID } = useChat()
    const [image, setImage] = useState<any>()
    // const [loading, setLoading] = useState<boolean>(true)
    const user_type = getUserTypeForMessage()
    const currentLang = useRecoilValue(currentLangState)
    const isRtl = useIsRtl()

    useEffect(() => {
      if (!message) return
      if (
        message_type_id === ConversationMessageTypes["image/png"] ||
        message_type_id === ConversationMessageTypes["image/jpg"] ||
        message_type_id === ConversationMessageTypes["image/jpeg"] ||
        message_type_id === ConversationMessageTypes["image/gif"]
      ) {
        getPhoto(file_name, group_name, user_id)
      }
      let temp = message
      if (!temp) return
      if (temp.length > MAX_MESSAGE_LENGTH_BEFORE_HIDING) {
        temp = temp.substring(0, MAX_MESSAGE_LENGTH_BEFORE_HIDING) + "..."
        setIsReadMore(true)
      }
      setFinalMessage(temp)
    }, [])

    const getPhoto = (
      file_name: string | undefined,
      group_name: string | undefined,
      user_id: string | undefined
    ) => {
      console.log("getting photo...")
      if (!file_name || !user_id || !group_name) return
      const formData = new FormData()
      formData.append("file_name", file_name)
      formData.append("user_id", user_id)
      formData.append("group_name", group_name)
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      axiosInstance
        .post("authenticated/conversation/recieve_file", formData, {
          headers: headers,
          responseType: "blob",
        })
        .then((data) => {
          console.log("download data", data.data)
          const url = URL.createObjectURL(data.data)
          setImage(url)
        })
        .catch((err) => console.error(err))
    }

    function getUserTypeForMessage() {
      const [aiID, patientID, physicianID] = group_name.match(
        /ai_([a-fA-F\d]+)?|pa_([a-fA-F\d]+)?|ph_([a-fA-F\d]+)?/g
      )

      if (aiID && patientID && physicianID) {
        if (user_id === aiID.split("_")[1] || user_id === "Assistant") {
          return USER_TYPE.ai
        } else if (user_id === patientID.split("_")[1]) {
          return USER_TYPE.patient
        } else if (user_id === physicianID.split("_")[1]) {
          return USER_TYPE.physician
        }
        return USER_TYPE.patient
      }
      const [aiIDMyAi, physicianIDMyAi] = group_name.match(/ai_([a-fA-F\d]+)?|ph_([a-fA-F\d]+)?/g)

      if (user_id === aiIDMyAi.split("_")[1] || user_id === "Assistant") {
        return USER_TYPE.ai
      } else if (user_id === physicianIDMyAi.split("_")[1]) {
        return USER_TYPE.physician
      }
      return USER_TYPE.patient
    }

    const toggleFullMessage = () => {
      setIsReadMore(false)
    }

    const formatMessage = (message: string) => {
      let newMessage = ""
      for (let i = 0; i < message.length; i++) {
        if (parseInt(message[i]) && message[i + 1] === "." && message[i + 2] === " " && i < message.length - 2) {
          newMessage += "\n" + message[i]
        } else if (i > 0 && !parseInt(message[i - 1]) && message[i] === "." && message[i + 1] === " " && i < message.length - 1) {
          newMessage += message[i] + "\n"
        } else {
          newMessage += message[i]
        }
      }

      if (isReadMore) {
        newMessage = isReadMoreMessage(newMessage)
      }

      const boldWordsWrappedInAsterisk = (line: string) => {
        const firstIndexOfAstreisk = line.indexOf("**")
        const lastIndexOfAstreisk = line.lastIndexOf("**")
        const wordToBold = line.slice(firstIndexOfAstreisk + 2, lastIndexOfAstreisk)
        return (
          <span>
            {line.slice(0, firstIndexOfAstreisk)}
            <strong>{wordToBold}</strong>
            {line.slice(lastIndexOfAstreisk + 2)}
          </span>
        )
      }

      const formattedMessage = newMessage.split("\n").map((line, index) => (
        <li key={index} style={{ textAlign: isRtl ? "right" : "left" }}>
          {line.includes("**") ? boldWordsWrappedInAsterisk(line) : line}
        </li>
      ))
      return <ul className="formatedMessage">{formattedMessage}</ul>
    }

    const formatBytes = (a: any, b = 2) => {
      if (!+a) return "0 Bytes"
      const c = 0 > b ? 0 : b,
        d = Math.floor(Math.log(a) / Math.log(1024))
      return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
        ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
      }`
    }

    const downloadFile = (
      file_name: string | undefined,
      group_name: string | undefined,
      user_id: string | undefined
    ) => {
      if (!file_name || !user_id || !group_name) return
      const formData = new FormData()
      formData.append("file_name", file_name)
      formData.append("user_id", user_id)
      formData.append("group_name", group_name)
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      axiosInstance
        .post("authenticated/conversation/recieve_file", formData, {
          headers: headers,
          responseType: "blob",
        })
        .then((data) => {
          console.log("download data", data.data)
          const url = URL.createObjectURL(data.data)
          console.log("url", url)
          const a = document.createElement("a")
          a.href = url
          a.download = getDisplayNameForFile(file_name)
          a.click()
        })
        .catch((err) => console.error(err))
    }

    const isReadMoreMessage = (message: string) => {
      return message.substring(0, MAX_MESSAGE_LENGTH_BEFORE_HIDING) + "..."
    }

    const downloadPrescriptionOrReferral = (
      prescription_or_referral: "prescriptions" | "referrals" | undefined,
      file_name: string | undefined,
      group_name: string | undefined,
      user_id: string | undefined
    ) => {
      if (!file_name || !user_id || !group_name || !prescription_or_referral) return
      const formData = new FormData()
      formData.append("prescription_or_referral", prescription_or_referral)
      formData.append("file_name", file_name)
      formData.append("user_id", user_id)
      formData.append("group_name", examRoomId)
      const headers = {
        "Content-Type": "multipart/form-data",
      }
      axiosInstance
        .post("authenticated/conversation/recieve_prescription_or_referral", formData, {
          headers: headers,
          responseType: "blob",
        })
        .then((data) => {
          console.log("download data", data.data)
          const url = URL.createObjectURL(data.data)
          console.log("url", url)
          const a = document.createElement("a")
          a.href = url
          a.download = getDisplayNameForReferalOrPrescription(file_name)
          a.click()
        })
        .catch((err) => console.error(err))
    }

    const getDisplayNameForReferalOrPrescription = (fileName: string | undefined) => {
      if (!fileName) {
        return ""
      }
      const fileArray = fileName.split("_")
      const parsed = `${fileArray[fileArray.length - 2]}-${fileArray[fileArray.length - 1]}`
      return parsed
    }

    const getDisplayNameForFile = (fileName: string | undefined) => {
      if (!fileName) {
        return ""
      }
      const indexOfUnderScore = fileName.indexOf("_")
      if (fileName[indexOfUnderScore + 1] === ".") {
        return fileName
      }
      const parsed = fileName.slice(indexOfUnderScore + 1)
      return parsed
    }

    let finalMessageComopnent = null

    if (conversation_handler === ConversationHandle.SKIP_UI) return <></>

    switch (message_type_id) {
      case ConversationMessageTypes.fakeMessage:
        finalMessageComopnent = (
          <div className="chatMessageContentWrapper">
            <div className="chatMessageContent">
              <p className="textContent" style={{ direction: isRtl ? "rtl" : "ltr" }}>{message}</p>
              {isReadMore && (
                <button className="readMore" onClick={toggleFullMessage}>
                  {currentLang["read_more"]}
                </button>
              )}
            </div>
            <p className="chatMessageTimeStamp">{getLastMessagePrefix(new Date(date_time))}</p>
          </div>
        )
        break
      case ConversationMessageTypes.text:
      case ConversationMessageTypes.speech:
      case ConversationMessageTypes.sendToPhysician:
      case ConversationMessageTypes.requestMoreDetails:
      case ConversationMessageTypes.sendMoreDetails:
      case ConversationMessageTypes.finalize:
      case ConversationMessageTypes.sendFileOcr:
      case ConversationMessageTypes.billing:
      case ConversationMessageTypes.summary:
      case ConversationMessageTypes.sendGuidelines:
      case ConversationMessageTypes.referral:
      case ConversationMessageTypes.prescription:
        finalMessageComopnent = (
          <div className="chatMessageContentWrapper">
            <div className="chatMessageContent">
              <p className="textContent" style={{ direction: isRtl ? "rtl" : "ltr" }}>
                {formatMessage(message)}
              </p>
              <p style={{ direction: isRtl ? "rtl" : "ltr", display: "none" }}>
                {message}
              </p>
              {isReadMore && (
                <button className="readMore" onClick={toggleFullMessage}>
                  {currentLang["read_more"]}
                </button>
              )}
            </div>
            <p className="chatMessageTimeStamp">{getLastMessagePrefix(new Date(date_time))}</p>
          </div>
        )
        break
      case ConversationMessageTypes.pdf:
      case ConversationMessageTypes.word:
      case ConversationMessageTypes.docx:
      case ConversationMessageTypes.doc:
        finalMessageComopnent = (
          <div className="chatMessageContentWrapper fileType">
            <div
              className="chatMessageFileContent cursorHover"
              onClick={() => downloadFile(file_name, group_name, user_id)}
            >
              <div className="fileIconWrapper">
                <div className="fileIconContainer">
                  <StaticImage src={Icons.file} alt="logo" />
                </div>
              </div>
              <Container.Column>
                <div>
                  <p className="fileNameText textContent">{message}</p>
                  <p className="fileNameText hidden textContent">{file_name}</p>
                </div>
                <p className="fileSizeText">{formatBytes(size)}</p>
              </Container.Column>
            </div>
            <p className="chatMessageTimeStamp">{getLastMessagePrefix(new Date(date_time))}</p>
          </div>
        )
        break
      case ConversationMessageTypes.requestReferal:
        finalMessageComopnent = (
          <div
            className="chatMessageContentWrapper fileType"
            onClick={() =>
              downloadPrescriptionOrReferral("referrals", message, examRoomId, user_id)
            }
          >
            <div className="chatMessageFileContent cursorHover">
              <div className="fileIconWrapper">
                <div className="fileIconContainer">
                  <StaticImage src={Icons.file} alt="logo" />
                </div>
              </div>
              <p className="fileNameText textContent">
                {getDisplayNameForReferalOrPrescription(message)}
              </p>
              <p className="fileNameText hidden textContent">{message}</p>
              {/* <p className="fileSizeText">{formatBytes(size)}</p> */}
            </div>
            <p className="chatMessageTimeStamp">{getLastMessagePrefix(new Date(date_time))}</p>
          </div>
        )
        break
      case ConversationMessageTypes.requestPrescription:
        finalMessageComopnent = (
          <div
            className="chatMessageContentWrapper fileType"
            onClick={() =>
              downloadPrescriptionOrReferral("prescriptions", message, examRoomId, user_id)
            }
          >
            <div className="chatMessageFileContent cursorHover">
              <div className="fileIconWrapper">
                <div className="fileIconContainer">
                  <StaticImage src={Icons.file} alt="logo" />
                </div>
              </div>
              <p className="fileNameText textContent">
                {getDisplayNameForReferalOrPrescription(message)}
              </p>
              <p className="fileNameText hidden textContent">{message}</p>
              {/* <p className="fileSizeText">{formatBytes(size)}</p> */}
            </div>
            <p className="chatMessageTimeStamp">{getLastMessagePrefix(new Date(date_time))}</p>
          </div>
        )
        break
      case ConversationMessageTypes.fileFailed:
        finalMessageComopnent = (
          <Container.Column className="fileType">
            <Container.Row justifyContent="center" alignItems="center" gap={2}>
              <Container.Row className="chatMessageFileContent cursorHover">
                <div className="fileIconWrapper">
                  <div className="fileIconContainer">
                    <StaticImage src={Icons.file} alt="file" />
                  </div>
                </div>
                <Container.Column>
                  <p className="fileNameText textContent">{message}</p>
                  <p className="fileSizeText">{formatBytes(size)}</p>
                </Container.Column>
              </Container.Row>
              <StaticImage src={Icons.failedUpload} alt="failedUpload" />
            </Container.Row>
            <p className="chatMessageTimeStamp">{date_time}</p>
          </Container.Column>
        )
        break
      case ConversationMessageTypes.seperator:
        finalMessageComopnent = (
          <div>
            <div></div>
            <p className="seperator">{message}</p>
            <div></div>
          </div>
        )
        break
      case ConversationMessageTypes["image/png"]:
      case ConversationMessageTypes["image/jpg"]:
      case ConversationMessageTypes["image/jpeg"]:
      case ConversationMessageTypes["image/gif"]:
        finalMessageComopnent = (
          <div className="fileType">
            <Container.Row className="chatMessageImageContainer">
              {image ? (
                <img src={image} />
              ) : (
                <StaticImage src={LoadingSpinner} alt="spinner" width={5} />
              )}
              <p className="fileNameText hidden textContent">{file_name}</p>
              {/* <p>test text of image</p> */}
            </Container.Row>
            <p className="chatMessageTimeStamp">{date_time}</p>
          </div>
        )
        break
      case ConversationMessageTypes.loadingFile:
        finalMessageComopnent = (
          <Container.Column className="fileType">
            <Container.Row className="chatMessageContent cursorHover">
              <div className="fileIconWrapper">
                <div className="fileIconContainer">
                  <StaticImage src={Icons.file} alt="logo" />
                </div>
              </div>
              <Container.Column gap={1}>
                <p className="fileNameText textContent">{message}</p>
                <p className="fileSizeText">{formatBytes(size)}</p>
                <progress value="0" max="100"></progress>
              </Container.Column>
            </Container.Row>
          </Container.Column>
        )
        break
      default:
        break
    }

    if (!finalMessageComopnent) {
      return <></>
    }

    return (
      <li
        ref={loadingMessageRef ? loadingMessageRef : null}
        className={user_id === userID ? "paitentMessage message" : "botMessage message"}
      >
        {message_type_id !== ConversationMessageTypes.seperator && (
          <>
            <UserAvatar
              user_name={user_name || ""}
              user_name_initials={user_name_initials || ""}
              userType={user_type}
              userId={user_id || ""}
            />
          </>
        )}

        {finalMessageComopnent}
      </li>
    )
  }
)
